<template>
  <van-nav-bar
      class="van-nav-bar refresh-nav-bar"
      title=""
      left-text=""
      right-text="刷新"

  >
      <template v-slot:right>
        <van-button @click="onRefresh">刷新</van-button>
        <van-button @click="onUnBindQy" v-if="show_unbind">解绑</van-button>
      </template>
  </van-nav-bar>
</template>

<script>

import {Toast} from "vant";
import {unbind} from "../../api/test";
import {clearCacheData} from "../../utils/base";
import { Dialog } from 'vant';

export default {
  props: ['show_unbind'],
  data() {
    return {
      clicked: false,
      clicked2: false,
    };
  },
  mounted(){

  },
  methods: {

    onRefresh() {

      if (this.clicked) {
        return;
      }
      sessionStorage.clear();
      this.clicked = true;
      this.$router.go(window.location.href);
    },

    async onUnBindQy() {

      if (this.clicked2) {
        return;
      }

      this.clicked2 = true;

      Dialog.confirm({
        message: '您确定要解绑当前企微账号吗',
      })
        .then(async () => {
          await unbind({}).then((res) =>{
            console.log(res,"result")
            if(res.code == 200){
              Toast.success('解绑成功');
              clearCacheData();
              this.$router.go(0);
            }else{
              Toast.fail(res.message)
            }
            this.clicked2 = false;
          });
        })
        .catch(() => {
          this.clicked2 = false;
        });
    }
  },
};
</script>

<style lang="less" scoped>
.van-nav-bar{
  position: sticky;
  top: 0;
}
.refresh-nav-bar:after{
 
}
.van-hairline--bottom{

}
::v-deep .van-button {
  color: #1989fa;
  border: none;
}
</style>
