<template>
  <div id="orderList" class="orders">
    <Refresh show_unbind="1" />
    <div class="orders-box">
      <div class="van-tabs">
       
        <form action="/">
          <van-search v-model="search" show-action :clearable="false"  placeholder="请输入搜索关键词"  @search="onSearch">
            <template #action>
              <div @click="onSearch" style="color: #1989fa;">搜索</div>
            </template>
          </van-search>
        </form>
        <van-tabs v-model="active" @click="onClick" title-active-color="#1F97EE" color="#1F97EE">
          <van-tab title="今日招聘" name="1"></van-tab>
          <van-tab title="明日招聘" name="2"></van-tab>
        </van-tabs>
      </div>
      <div v-if="orders.length!=0">
        <ul>
          <li class="orders-item" v-for="order in orders" :key="order.id" @click="goToDetail(order.id)">
            <div class="orders-item-left"><img :src="order.logo" /></div>
            <div class="orders-item-right">
              <div class="orders-item-title">{{ order.job_title }}</div>
              <div class="orders-item-label">
                <div v-for="label in order.label_data" :key="label">{{ label }}</div>
              </div>
              <div class="orders-item-price">
                <div>
                  <span class="price">{{ order.start_salary }}-{{ order.end_salary }}</span><span
                    class="text">元/月</span>
                </div>
                <div class="remaining">剩余名额 <span class="remaining_num">{{order.usable_number}}</span> </div>
              </div>
              <div class="orders—btn">
                <van-button type="info" size="mini" @click.stop="send(order, order.id)">发送订单</van-button>
                <van-button type="info" size="mini" class="orders—btn-item"
                  @click.stop="SignUp(order.id,'帮他报名')">帮他报名</van-button>
                <van-button type="info" size="mini" class="orders—btn-item"
                  @click.stop="SignUp(order.id,'帮其他人报名')">帮其他人报名</van-button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-else style="background-color: #fff;">
        <van-empty description="暂无更多订单~" />
      </div>
    </div>
    <!-- 帮他报名/帮其他人报名弹窗 -->
    <van-dialog :closeOnPopstate="true" v-model="dialogVisible" :title="title" :showConfirmButton="false">
      <div style="max-height: 500px;overflow: auto;padding:10px;">
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>会员姓名</div>
          <input type="text" v-model="pram.real_name" placeholder="请输入会员姓名" />
        </div>
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>手机号</div>
          <input type="number" v-model="pram.phone" placeholder="请输入手机号" />
        </div>
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>身份证号</div>
          <div class="orders_list_right">
            <input type="text" v-model="pram.id_number" placeholder="请输入身份证号" @input="blurIdNumber" :maxlength="18" />
            <van-uploader :after-read="afterRead">
              <van-icon name="scan" size="26" color="#1989fa" />
            </van-uploader>
          </div>
        </div>
        <div class="orders_list">
          <div class="orders_list_left"><span>*</span>前往方式</div>
          <van-radio-group v-model="pram.way_to_go" direction="horizontal">
            <van-radio :name="item.type" v-for="(item,index) in radioList" :key="index">{{ item.title }}</van-radio>
          </van-radio-group>
        </div>
        <div class="orders_list" v-if="!isShowmemberSourceList">
          <div class="orders_list_left"><span>*</span>会员来源</div>
          <van-radio-group v-model="pram.member_source" direction="horizontal">
            <van-radio style="margin-bottom: 5px;" :name="item.status" v-for="(item,index) in memberSourceList"
              :key="index">{{ item.title }}</van-radio>
          </van-radio-group>
        </div>
      </div>
      <van-button style="width: 50%;" type="default" @click="onCancel">取消</van-button>
      <van-button style="width: 50%;" type="info" @click="isShowconfirm?confirm():''">确定</van-button>
    </van-dialog>

  </div>
</template>
<script>
import {Toast,Dialog } from "vant";
import {getOrderList,uploadFile,nexDayList,ocrIdcard,getKehuDetail,getPositionKey,qyToolHelpEnroll,hasMemberSource} from "../../api/test";
import {login} from "../../api/test";
import {checkBind, setEnterPath,checkLoadWxConfig,getUserInfo,} from "../../utils/base";

import Refresh from "@/components/home/refresh.vue";
const wx = window.wx;
export default {
  components: {
    Refresh
  },
  data() {
    return {
      orders: [],
      active: 1,
      dialogVisible:false,
      pram:{
        enterprise_position_id:'',
        real_name:'',
        phone:'',
        id_number:'',
        way_to_go:10,
        member_source:10,
        domicile:'',
        nation:''
      },
      radioList:[
        {type:20,title:"自行到场"},
        {type:10,title:"门店集合"}
      ],
      memberSourceList:[
        {status:10,title:"非网络渠道"},
        {status:20,title:"抖音"},
        {status:30,title:"58"},
        {status:40,title:"boss"},
        {status:50,title:"公众号"},
        {status:60,title:"小程序"},
        {status:70,title:"高德"},
        {status:80,title:"快手"},
      ],
      isShowmemberSourceList:true,
      title:'帮他报名',
      isShowconfirm:true,
      search:''
    }
  },

  created () {

    // 判断绑定
    if (!checkBind(this.$router)) {
      return false;
    }
    // 判断初始化
    if (!checkLoadWxConfig(this.$router)) {
      return false;
    }
    // 获取订单
    let isShowLoad=sessionStorage.getItem('isShowLoad')
    if(isShowLoad){
      this.orders=JSON.parse(sessionStorage.getItem('orders'))
      this.active=sessionStorage.getItem('active')
    }else{
      this.getOrderListFn();
    }
  },

  methods: {
    blurIdNumber(){
      if(this.pram.id_number&&this.pram.id_number.length===18){
        let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if(reg.test(this.pram.id_number) == false){
          Toast('身份证号码有误')
        }else{
              // 显示加载提示
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        hasMemberSource({id_number:this.pram.id_number}).then(res=>{
        if(res.code==200){
          this.isShowmemberSourceList=res.data.has_member_source
          setTimeout(() => {
        // 隐藏加载提示
            Toast.clear();
          }, 500);
        }
      })
        }
    
      }else if(!this.pram.id_number||this.pram.id_number.length<18){
        this.isShowmemberSourceList=true
      }
    },
    getUserId() {
      let userInfo = getUserInfo();
      let qy_out_user_id = userInfo.qy_out_user_id;
      return qy_out_user_id;
    },
    async getShow() {
      let res = await getKehuDetail({
        qy_out_user_id: this.getUserId(),
        type: 0,
        visit_type: 1,
      });
      if (res.code == 200) {
        this.pram.real_name=res.data.user_detail.real_name
        this.pram.id_number=res.data.user_detail.id_number
        this.pram.phone=res.data.user_detail.phone
        this.pram.domicile= res.data.user_detail.domicile
        this.blurIdNumber()
        this.dialogVisible=true
      }
    },
    async afterRead(file) {
      let that=this
      const form = new FormData()
      Toast.loading({
        message: '身份证识别中...',
        forbidClick: true,
      });
      form.append('file', file.file)
      // 此时可以自行将文件上传至服务器
      let res = await uploadFile(form)
      if (res.code == 200) {
        ocrIdcard({img_path: res.data.file_full_path}).then(request=>{
          if(request.code==200){
            if(request.data.errcode==0){
              that.pram.id_number= request.data.id
              that.pram.real_name= that.pram.real_name?that.pram.real_name:request.data.name
              that.pram.nation= request.data.nationality+'族'
              that.pram.domicile= request.data.addr
              that.blurIdNumber()
            }else{
              Dialog.alert({
                title: '未能识别身份证',
                message: '请重新拍摄或上传',
                confirmButtonColor:'#1989fa'
              }).then(() => {
                // on close
              });
            }
          }
          
        })
      }
    },

    async getOrderListFn() {
      await getOrderList({type:0,search:this.search}).then((res) => {
        if (res.code == 200) {
          this.orders = res.data.data;
        }
      })
    },
    async nexDayListFn() {
      await nexDayList({search:this.search}).then((res) => {
        if (res.code == 200) {
          this.orders = res.data.positionList.data;
        }
      })
    },
    onSearch(){
      if(this.active==1){
        this.getOrderListFn()
      }else{
        this.nexDayListFn()
      }
    },
    onClick(event) {
      this.active=event
      if(event==1){
        this.getOrderListFn()
      }else{
        this.nexDayListFn()
      }
    },
    SignUp(id,type){
      this.isShowmemberSourceList=true
      if(type=='帮他报名'){
        this.getShow()
        this.title='帮他报名'
      }else  if(type=='帮其他人报名'){
        this.title='帮其他人报名'
        this.pram=this.$options.data().pram
        this.dialogVisible=true
      }
      this.pram.enterprise_position_id=id
    },
    onCancel() {
      this.dialogVisible = false
    },
    confirm() {
      this.isShowconfirm=true
      let title=this.checkout()
      if(!title){
            // 显示加载提示
      Toast.loading({
        message: '报名中...',
        forbidClick: true,
      });
      this.isShowconfirm=false
        qyToolHelpEnroll(this.pram).then(res=>{
          if(res.code==200){
            this.isShowconfirm=true
            Toast('报名成功')
            setTimeout(()=>{
              this.dialogVisible=false
              Toast.clear();
            },500)
          }else{
            this.isShowconfirm=true
          }
        })
      }else{
        Toast(title);
      }
    },
    checkout(){
      let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let rePhone = /^1[3456789]\d{9}$/;
      if(!this.pram.real_name){
        return '请输入会员姓名'
      } else if(!this.pram.phone){
        return '请输入手机号'
      }else if (rePhone.test(this.pram.phone) == false) {
        return '手机号码错误'
      } else if(!this.pram.id_number){
        return '请输入身份证号'
      } else if (reg.test(this.pram.id_number) == false) {
        return '身份证号码有误'
      }else if(!this.pram.way_to_go){
        return '请选择前往方式'
      }else if(!this.isShowmemberSourceList&&!this.pram.member_source){
        return '请选择会员来源'
      }
    },
    goToDetail(id){
      this.$router.push({
        path: `/order/detail/${id}`
      });
      sessionStorage.setItem('isShowLoad',true)
      sessionStorage.setItem('orders',JSON.stringify(this.orders))
      sessionStorage.setItem('active',this.active)
    },
    send(order, orderId) {
      getPositionKey({ position_id: orderId }).then(res => {
        if (res.code == 200) {
          let details = {};
          details.scene = res.data
          wx.invoke('sendChatMessage', {
            msgtype: "miniprogram", //消息类型，必填
            enterChat: true, //为true时表示发送完成之后顺便进入会话，仅移动端3.1.10及以上版本支持该字段
            miniprogram:
            {
              appid: "wx3bb67ab45cae2155",//小程序的appid，企业已关联的任一个小程序
              title: order.job_title, //小程序消息的title
              imgUrl: order.logo,//小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
              page: `pages/home/pages/details2/details2.html?details=${JSON.stringify(details)} `, //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
            },
          }, function (res) {
            if (res.err_msg == 'sendChatMessage:ok') {
              //发送成功
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.orders img{
  border-radius: 3px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: relative;
}
.orders-item {
  padding: 20px 10px;
  clear: both;
  display:flex;
  align-items: center;
  background: #fff;
  margin-bottom: 5px;
  
}
.orders-item-left {
  /* float: left; */
}
.orders-item-right {
  /* float: left; */
  margin-left: 10px;
  flex: 1;
}
.orders-item-title {
  font-size: 1.2em;
  /* margin-top: 10px; */
  overflow: hidden;
  text-overflow: ellipsis;
}
.orders-item-label {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.orders-item-label div{
  margin-right: 5px;
  font-size: 1em;
  color: #1F97EE;
  background-color: #E9F5FF;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 100%;
}
.orders-item-price {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.orders-item-price .price{
  color: #FF4E3A;
  font-weight: bold;
  font-size: 1.2em;
}
.orders-item-price .text {
  color: #A2A2A2;
}
.orders-item .send {
  height: auto;
  padding: 3px 15px;
  margin-left: 10px;
}

.remaining{
    font-size: 12px;
    color: #999999;
    text-align: right;
}
.remaining_num{
    color: #ff603e;
    font-weight: 400;
}
/* .orders—btn{
  display: flex;
  te
} */
.orders—btn-item{
  margin-left: 10px !important;
}
.van-tabs{
  position: sticky;
  top: 46px;
  z-index: 999;
}
.orders-box{
  background: #f5f5f5;
}
.orders_list_left {
  margin-right: 5px;
  flex-shrink: 0;
  width: 28%;
}

.orders_list_left span {
  color: #E25050;
  margin-left: 2px;
}

.orders_list {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 0;
  box-sizing: border-box;
}
.orders_list:last-child{
  border-bottom:none;
}

.orders_list input {
  /* text-align: right; */
  /* flex: 1; */
  font-size: 15px;
  font-weight: 300;
  color: #ABABAB;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;
}
.orders_list_right{
  display: flex;
  text-align: center;
}
.orders_list_right input{
  width: 80%;
}
.index_search {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #F5F5F5;
  height: 70rpx;
  z-index: 96;
  border-radius: 40rpx;
  padding: 5rpx 5rpx 5rpx 30rpx;
  box-sizing: border-box;
}

</style>
